import React from "react";
import { Link } from "gatsby";

import "../components/section.css";
import "../components/globalStyle.css";

import Header from "../components/header";
import ProjectCard from "../components/projectCard.js";
import Test from "../components/test";

const DigitalSection = () => (
  <div className="DigitalSectionGroup">
    <Header />

    <div className="section">
      <div className="sectionText">
        <h2 className="sectionTitle">DESIGN AND RESEARCH</h2>
        <div className="sectionBody">
          <p>
            I believe that the best products are built by teams who embrace
            curiosity, open-mindedness, and resilience. As a product designer, I
            employ a collaborative, iterative, research-oriented approach to
            build great ideas into great user experiences.
            <br />
            <br />
            My process is loosely:
          </p>
          <ol>
            <li>
              <b>Communicate</b> to align the team, find our users, and identify
              gaps in knowledge
            </li>

            <li>
              <b>Research</b> to validate our assumptions, study the context,
              and gain a deeper understanding of our users' needs
            </li>

            <li>
              <b>Design</b> to build out potential solutions to test with
              stakeholders
            </li>

            <li>
              <b>Validate</b> with users to stress test the prototype and
              identify weaknesses and strengths
            </li>

            <li>
              <b>Iterate</b> to act on the feedback from user testing and build
              a better, stronger experience
            </li>

            <li>
              <b>Repeat steps 3-5</b> to further refine the prototype
            </li>
          </ol>
          <br />
          <br />
        </div>
      </div>

      <div className="sectionImages">
        <Link to="./omni">
          <ProjectCard
            Icon={require("../img/projectOmni.svg")}
            Name="CAPITAL ONE"
            Text="a design system for the Commercial Bank"
          />
        </Link>

        <Link to="./infura">
          <ProjectCard
            Icon={require("../img/projectInfura.svg")}
            Name="INFURA"
            Text="a developer dashboard for decentralized apps"
          />
        </Link>

        <Link to="./metamask">
          <ProjectCard
            Icon={require("../img/projectMetaMask.svg")}
            Name="METAMASK"
            Text="designing transactions on ethereum"
          />
        </Link>

        <Link to="./liquality">
          <ProjectCard
            Icon={require("../img/projectLiquality.png")}
            Name="LIQUALITY"
            Text="a platform for peer to peer atomic swaps"
          />
        </Link>

        <Link to="./ethereumMap">
          <ProjectCard
            Icon={require("../img/projectEthereumMap2.svg")}
            Name="ETHEREUM MAP"
            Text="a comprehensive view of the blockchain"
          />
        </Link>

        <Link to="./TAL">
          <ProjectCard
            Icon={require("../img/projectLocalties.png")}
            Name="LABS DESIGN"
            Text="research for a start up accelerator"
          />
        </Link>

        <Link to="./graphics">
          <ProjectCard
            Icon={require("../img/projectGraphics.svg")}
            Name="GRAPHICS"
            Text="t-shirts, brand designs, and other pretty things"
          />
        </Link>
      </div>
    </div>
  </div>
);

export default DigitalSection;
